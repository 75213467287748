import { render, staticRenderFns } from "./addBank.vue?vue&type=template&id=748852b7&scoped=true&"
import script from "./addBank.vue?vue&type=script&lang=js&"
export * from "./addBank.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "748852b7",
  null
  
)

export default component.exports