<template>
  <el-dialog title="新增存款卡" :visible.sync="dialogVisible" width="79%" :before-close="handleClose">
    <el-form v-model="bankFrom" label-width="100px">
      <!--  水费  -->
      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="银行卡类型" width="120px">
            <el-select
              v-model="bankFrom.bankType"
              filterable
              placeholder="请选择"
              @change="bandTypeButton"
            >
              <el-option
                v-for="item in optionBelong"
                :key="item.key"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="选择银行" width="120px">
            <el-select v-model="bankList" filterable placeholder="请选择">
              <el-option
                v-for="item in bankListOption"
                :key="item.id"
                :label="item.bankName"
                :value="{label:item.bankCode,value:item.id,text:item.bankName}"
              ></el-option>
            </el-select>
            {{this.bankList.label}}
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="支行名称" width="120px">
            <el-input width="20px" v-model="bankFrom.subbranch"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="二级卡类型" width="120px" v-if="this.bankType==11">
            <el-select v-model="bankFrom.bankTwoType" filterable placeholder="请选择">
              <el-option
                v-for="item in bankTwoTypeList"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="卡号" width="120px">
            <el-input-number width="20px" v-model="bankFrom.bankNo" />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="卡地址" width="120px" v-if="this.bankType==11">
            <el-select v-model="bankFrom.provinceCode" filterable placeholder="请选择">
              <el-option
                v-for="item in provinceCodeList"
                :key="item.areaCode"
                :label="item.areaName"
                :value="item.areaCode"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <!--  电费  -->
      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="存入资金" width="120px" v-if="this.bankFrom.bankType!=1">
            <el-input-number
              width="20px"
              v-model="bankFrom.depositedMoney"
              @change="editPreSumButton()"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="用途" width="120px">
            <el-input width="20px" v-model="bankFrom.purpose"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="备注" width="120px">
            <el-input width="20px" v-model="bankFrom.remark"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7" v-if="this.bankFrom.bankType===1">
          <el-form-item label="未投入金额" width="120px">
            <el-input-number
              width="20px"
              v-model="bankFrom.noPutMoney"
              @change="editPreSumButton()"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <!-- <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="预计总入资金" width="120px">
            <el-input width="20px" v-model="bankFrom.preDepositedMoney" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="预计所有投入金额" width="120px">
            <el-input width="20px" v-model="bankFrom.preSumDepositedMoney" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>-->
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitButton()">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { formatDate } from "@/plugins/data.js";
import { getSelectFundList, addBank } from "@/api/capital/bank/bank";
import { getFundEnumList } from "@/api/commonApi/commonInternal/commonInternal";
import { getAreaDataApi } from "@/api/system/mune/menuCoupon.js";
export default {
  name: "AddBank",
  data() {
    return {
      dialogVisible: false,
      bankList: {
        value: null,
        text: null,
        label: null
      },
      bankListOption: [],
      bankTwoTypeList: [
        {
          key: "1",
          value: "普通卡"
        }
      ],
      bankFrom: {
        noPutMoney: 0,
        depositedMoney: 0,
        purpose: null,
        remark: null,
        bankInfoId: null,
        bankCode: null
      },
      optionBelong: [],
      bankType: 0,
      provinceCodeList: []
    };
  },
  created() {
    this.getSelectFundListButton();
    this.getFundEnumListButton();
    this.getAreaDataButton();
  },
  methods: {
    //打开弹窗
    showFund() {
      this.dialogVisible = true;
    },
    //
    ok() {
      this.$emit("closeModal", false);
      this.$Message.info("Clicked ok");
    },
    cancel() {
      this.$emit("closeModal", false);
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    clear() {
      this.bankFrom.depositedMoney = 0;
      this.bankFrom.preDepositedMoney = 0;
      this.bankFrom.preSumDepositedMoney = 0;
      this.bankFrom.remark = null;
      this.bankFrom.purpose = null;
      this.bankFrom.preDepositedMoney = 0;
      this.bankFrom.preSumDepositedMoney = 0;
      this.bankFrom.noPutMoney = 0;
      this.bankFrom.bankNo = null;
    },
    bandTypeButton(val) {
      this.clear();
      // this.bankFrom.provinceCode = '';
      this.bankType = val;
    },
    getSelectFundListButton() {
      getSelectFundList().then(res => {
        this.bankListOption = res.result;
      });
    },
    getFundEnumListButton() {
      getFundEnumList({ enumName: "BankTypeEnum" }).then(res => {
        this.optionBelong = res.result;
      });
    },
    editPreSumButton() {},
    submitButton() {
      this.bankFrom.bankInfoId = this.bankList.value;
      this.bankFrom.bankCode = this.bankList.label;
      addBank(this.bankFrom).then(
        res => {
          this.clear();
          this.$message.success(res.message);
          this.$parent.getBankList();
          this.dialogVisible = false;
        },
        err => {}
      );
    },
    getAreaDataButton() {
      getAreaDataApi({ level: 1 }).then(res => {
        this.provinceCodeList = res.result;
      });
    }
  }
};
</script>
<style lang="less" scoped>
</style> 